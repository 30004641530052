import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect, useRef, Fragment, useReducer } from "react";

import { Resizable } from "re-resizable";
import Draggable from "react-draggable";

import html2canvas from "html2canvas";

const imageUrl = "/photo.jpeg";

const DEFAULT_PARAMS = {
  rows: 2,
  cols: 3,
  aspectH: 16,
  aspectW: 9,
  gap: 0,
  backgroundColor: "cyan",
  borderThickness: "0",
  borderColor: "gray",
};

const NUM_PARAMS = ["rows", "cols", "aspectH", "aspectW", "gap"];

function resetZoom() {
  console.log(navigator.userAgent);
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i)
  ) {
    var viewportmeta = document.querySelector('meta[name="viewport"]');
    if (viewportmeta) {
      viewportmeta.setAttribute(
        "content",
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, shrink-to-fit=no"
      );
      viewportmeta.setAttribute(
        "content",
        "width=device-width, minimum-scale=1.0, initial-scale=1.0, shrink-to-fit=no"
      );
    }

    var viewportContentOrig = viewportmeta.getAttribute("content");
    viewportmeta.setAttribute(
      "content",
      "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, shrink-to-fit=no"
    );
    viewportmeta.setAttribute("content", viewportContentOrig);
  }
}

function save(e) {
  html2canvas(document.querySelector("#grid")).then((canvas) => {
    // let image = canvas
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");

    // let link = document.createElement("a");
    // link.download = "canvas-image.png";
    // link.href = image;
    // link.click();

    console.log(canvas);

    // document.body.appendChild(canvas);

    canvas.toBlob((blob) => window.open(URL.createObjectURL(blob), "_blank"));
  });
}

function App() {
  const [params, setParams] = useState();
  const containerRef = useRef(null);
  const [containerAspectRatio, setContainerAspectRatio] = useState(null);
  const [embedWidth, setEmbedWidth] = useState();
  const [embedHeight, setEmbedHeight] = useState();

  const calculateContainerAspectRatio = () => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;
      const ratio = width / height;
      setContainerAspectRatio(ratio);

      if (params) {
        const aspectRatio = params.aspectW / params.aspectH;
        setEmbedWidth(ratio > aspectRatio ? "auto" : null);
        setEmbedHeight(ratio > aspectRatio ? "90dvh" : null);
      }

      // const containerHeight = containerRef.current.offsetHeight;
      // const embed = containerRef.current.querySelector("div");

      // embed.style.height = `${containerHeight}px`;
    }
  };

  function submitParams(e) {
    e.preventDefault();

    const formData = new FormData(e.target);

    const newParams = Object.keys(DEFAULT_PARAMS).reduce(
      (params, key) => ({
        ...params,
        [key]: formData.get(key),
      }),
      {}
    );

    console.log(newParams);

    setParams(newParams);

    window.history.pushState(
      { page: "grid", params: newParams, userInitiated: true },
      "",
      "/"
    );

    // resetZoom();

    console.log(9);
  }

  useEffect(() => {
    if (!params) {
      window.history.replaceState({ page: "form", params: null }, "", "/");
    }

    // Add a popstate event listener when the component is mounted
    const handlePopState = (event) => {
      console.log("popstate event:", event);
      // Handle the popstate event here
      if (event.state && event.state.params && event.state.userInitiated) {
        console.log("event state params", event.state);
        Object.keys(event.state.params).forEach((k) => {
          if (NUM_PARAMS.includes(k)) {
            event.state.params[k] = parseInt(event.state.params[k]);
          }
        });
        console.log(event.state.params);
        setParams(event.state.params);
      } else {
        setParams(null);
      }
    };

    window.addEventListener("popstate", handlePopState);

    calculateContainerAspectRatio();

    window.addEventListener("resize", calculateContainerAspectRatio);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("resize", calculateContainerAspectRatio);
    };
  }, [params]); // Empty dependency array means this effect runs once after the initial render

  // let orientation, embedWidth, embedHeight;
  // if (params) {
  //   orientation = params.aspectH > params.aspectW ? "portrait" : "landscape";
  //   embedWidth = orientation === "portrait" ? "auto" : "100%";
  //   embedHeight = orientation === "portrait" ? "100%" : "auto";
  // }

  // console.log("container", containerAspectRatio);
  // console.log(aspectRatio, embedWidth, embedHeight);

  // useEffect(() => {
  // document
  //   .querySelectorAll(".image-wrapper .drag-overlay")
  //   .forEach((overlay) => {
  //     console.log(overlay);
  //     let isDragging = false;
  //     let isPinching = false;
  //     let startX,
  //       startY,
  //       initialX = 0,
  //       initialY = 0;
  //     let startDistance = null;
  //     let scale = 1,
  //       lastScale = 1;
  //     let image = overlay.previousElementSibling;
  //     function getDistance(touches) {
  //       let dx = touches[0].pageX - touches[1].pageX;
  //       let dy = touches[0].pageY - touches[1].pageY;
  //       return Math.sqrt(dx * dx + dy * dy);
  //     }
  //     function updateTransform() {
  //       image.style.transform = `translate(${initialX}px, ${initialY}px) scale(${scale})`;
  //     }
  //     function handleTouchStart(e) {
  //       if (e.touches.length === 1) {
  //         // Single touch - prepare for dragging
  //         startX = e.touches[0].clientX;
  //         startY = e.touches[0].clientY;
  //         isDragging = true;
  //         isPinching = false;
  //       } else if (e.touches.length === 2) {
  //         // Two touches - prepare for pinching
  //         startDistance = getDistance(e.touches);
  //         isPinching = true;
  //         isDragging = false;
  //         e.preventDefault(); // Prevent default page zoom
  //       }
  //     }
  //     function handleTouchMove(e) {
  //       if (isDragging && e.touches.length === 1) {
  //         e.preventDefault(); // Prevent scrolling and other default actions
  //         let deltaX = (e.touches[0].clientX - startX) / lastScale;
  //         let deltaY = (e.touches[0].clientY - startY) / lastScale;
  //         initialX += deltaX;
  //         initialY += deltaY;
  //         startX = e.touches[0].clientX;
  //         startY = e.touches[0].clientY;
  //         updateTransform();
  //       } else if (isPinching && e.touches.length === 2) {
  //         let currentDistance = getDistance(e.touches);
  //         if (startDistance) {
  //           scale = lastScale * (currentDistance / startDistance);
  //           updateTransform();
  //         }
  //         e.preventDefault(); // Prevent default page zoom
  //       }
  //     }
  //     function handleTouchEnd(e) {
  //       if (e.touches.length < 2) {
  //         lastScale = scale; // Save the scale when pinching ends
  //         isPinching = false;
  //       }
  //       if (e.touches.length === 0) {
  //         isDragging = false;
  //       }
  //     }
  //     console.log(1);
  //     overlay.addEventListener("touchstart", handleTouchStart, {
  //       passive: false,
  //     });
  //     overlay.addEventListener("touchmove", handleTouchMove, {
  //       passive: false,
  //     });
  //     overlay.addEventListener("touchend", handleTouchEnd);
  //   });
  // }, []);

  if (!params) {
    return (
      <div>
        {" "}
        <form id="intro-form" onSubmit={submitParams}>
          <div>
            I want a grid with{" "}
            <input
              type="number"
              id="cols"
              name="cols"
              defaultValue={DEFAULT_PARAMS.cols}
            />{" "}
            by{" "}
            <input
              type="number"
              id="rows"
              name="rows"
              defaultValue={DEFAULT_PARAMS.rows}
            />{" "}
            cells and aspect ratio{" "}
            <input
              type="number"
              id="aspectW"
              name="aspectW"
              step="any"
              defaultValue={DEFAULT_PARAMS.aspectW}
            />
            :
            <input
              type="number"
              id="aspectH"
              name="aspectH"
              step="any"
              defaultValue={DEFAULT_PARAMS.aspectH}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label for="gap">Gap</label>
            <input
              type="number"
              name="gap"
              step="any"
              defaultValue={DEFAULT_PARAMS.gap}
            />
          </div>
          <div>
            <label for="gap">Background color</label>
            <input
              id="gap"
              name="backgroundColor"
              defaultValue={DEFAULT_PARAMS.backgroundColor}
            />
          </div>
          <div>
            <label for="gap">Border thickness</label>
            <input
              name="borderThickness"
              type="number"
              step="any"
              defaultValue={DEFAULT_PARAMS.borderThickness}
            />
          </div>
          <div>
            <label for="gap">Border color</label>
            <input
              name="borderColor"
              defaultValue={DEFAULT_PARAMS.borderColor}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <button htmlType="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }

  // return (
  //   <div class="full-container1">
  //     {/* <div class="container1" ref={containerRef}>
  //       <div className="embed1"></div>
  //     </div>
  //     <button id="saveButton" onClick={save}>
  //       Save View
  //     </button> */}
  //   </div>
  // );

  return (
    <div class="full-container">
      <div class="container" ref={containerRef}>
        {/* <style>
          {`
              @container resize-box (aspect-ratio > ${params.aspectW} / ${params.aspectH}) {
                .embed {
                  width: auto;
                  height: 100%;
                }
              }
            `}
        </style> */}
        <div
          className="embed"
          style={{
            aspectRatio: `${params.aspectW} / ${params.aspectH}`,
            width: embedWidth,
            height: embedHeight,
          }}
        >
          {/* <div
            id="grid"
            className="grid-container"
            style={{ backgroundColor: params.backgroundColor }}
          >
            <img src={imageUrl} style={{ width: "100%" }} />
          </div> */}
          <div
            id="grid"
            className="grid-container"
            style={{
              gridTemplateColumns: Array.from(
                { length: params.cols },
                () => "1fr"
              ).join(" "),
              gap: params.gap,
              backgroundColor: params.backgroundColor,
            }}
          >
            {Array.from({ length: params.rows * params.cols }, (_, index) => (
              <div
                className="image-wrapper"
                style={{
                  flex: "1",
                  border: params.borderThickness
                    ? `${params.borderThickness}px solid ${params.borderColor}`
                    : "none",
                }}
              >
                <Image key={index} />
              </div>
            ))}
          </div>
          <div
            className="grid-container"
            style={{
              gridTemplateColumns: Array.from(
                { length: params.cols },
                () => "1fr"
              ).join(" "),
              gap: params.gap,
              backgroundColor: "transparent",
              pointerEvents: "none",
            }}
          >
            {Array.from({ length: params.rows * params.cols }, (_, index) => (
              <div
                className="image-wrapper"
                style={{
                  flex: "1",
                  border: `1px dotted gray`,
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer">
        <button id="saveButton" onClick={save}>
          Save
        </button>
        <div>
          Click on a cell to select image. Click outside selected image to
          remove. Grid is for guidance only, it will not be visible on save.
        </div>
      </div>
    </div>
  );
}

function Image() {
  const [image, setImage] = useState();
  const imageRef = useRef();
  const overlay = useRef();

  let isDragging = false;
  let isPinching = false;
  let startX,
    startY,
    initialX = 0,
    initialY = 0;
  let startDistance = null;
  let scale = 1,
    lastScale = 1;

  function getDistance(point1, point2) {
    let dx = point1.pageX - point2.pageX;
    let dy = point1.pageY - point2.pageY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  function updateTransform() {
    imageRef.current.style.transform = `translate(${initialX}px, ${initialY}px) scale(${scale})`;
  }

  function handleStart(event) {
    if (event.touches && event.touches.length === 1) {
      // Single touch - prepare for dragging
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      isDragging = true;
      isPinching = false;
    } else if (event.touches && event.touches.length === 2) {
      // Two touches - prepare for pinching
      startDistance = getDistance(event.touches[0], event.touches[1]);
      isPinching = true;
      isDragging = false;
      event.preventDefault(); // Prevent default page zoom
    } else if (event.button === 0) {
      // Mouse left button click - prepare for dragging
      startX = event.clientX;
      startY = event.clientY;
      isDragging = true;
      isPinching = false;
    }
  }

  function handleMove(event) {
    if (isDragging && event.touches && event.touches.length === 1) {
      event.preventDefault(); // Prevent scrolling and other default actions
      let deltaX = (event.touches[0].clientX - startX) / lastScale;
      let deltaY = (event.touches[0].clientY - startY) / lastScale;
      initialX += deltaX;
      initialY += deltaY;
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      updateTransform();
    } else if (isPinching && event.touches && event.touches.length === 2) {
      let currentDistance = getDistance(event.touches[0], event.touches[1]);
      if (startDistance) {
        scale = lastScale * (currentDistance / startDistance);
        updateTransform();
      }
      event.preventDefault(); // Prevent default page zoom
    } else if (isDragging && event.buttons === 1) {
      // Check if left mouse button is pressed
      let deltaX = (event.clientX - startX) / lastScale;
      let deltaY = (event.clientY - startY) / lastScale;
      initialX += deltaX;
      initialY += deltaY;
      startX = event.clientX;
      startY = event.clientY;
      updateTransform();
    }
  }

  function handleEnd(event) {
    if (
      (event.touches && event.touches.length < 2) ||
      (!event.touches && event.button === 0)
    ) {
      lastScale = scale; // Save the scale when pinching or mouse dragging ends
      isPinching = false;
    }
    if (event.touches && event.touches.length === 0) {
      isDragging = false;
    }
  }

  function handleWheel(event) {
    // Calculate the mouse position relative to the image container
    const containerBounds = imageRef.current.getBoundingClientRect();
    const mouseX = event.clientX - containerBounds.left;
    const mouseY = event.clientY - containerBounds.top;

    // Check if the mouse position is within the bounds of the image container
    if (
      mouseX >= 0 &&
      mouseX <= containerBounds.width &&
      mouseY >= 0 &&
      mouseY <= containerBounds.height
    ) {
      const delta = event.deltaY;

      // Define a scale factor for zooming
      const scaleFactor = 0.1;

      // Calculate the new scale based on the wheel direction
      if (delta > 0) {
        scale -= scaleFactor;
      } else {
        scale += scaleFactor;
      }

      // Ensure that the scale stays within a reasonable range
      // scale = Math.min(Math.max(scale, 0.1), 3);

      // Update the transform with the new scale
      updateTransform();
    }
  }

  useEffect(() => {
    if (overlay && overlay.current) {
      overlay.current.addEventListener("touchstart", handleStart, {
        passive: false,
      });
      overlay.current.addEventListener("touchmove", handleMove, {
        passive: false,
      });
      overlay.current.addEventListener("touchend", handleEnd);

      // Add mouse event listeners
      overlay.current.addEventListener("mousedown", handleStart);
      overlay.current.addEventListener("mousemove", handleMove);
      overlay.current.addEventListener("mouseup", handleEnd);
      overlay.current.addEventListener("wheel", handleWheel);

      return () => {
        // Clean up event listeners
        if (overlay && overlay.current) {
          overlay.current.removeEventListener("touchstart", handleStart);
          overlay.current.removeEventListener("touchmove", handleMove);
          overlay.current.removeEventListener("touchend", handleEnd);
          overlay.current.removeEventListener("mousedown", handleStart);
          overlay.current.removeEventListener("mousemove", handleMove);
          overlay.current.removeEventListener("mouseup", handleEnd);
          overlay.current.removeEventListener("wheel", handleWheel);
        }
      };
    }
  }, []);

  function handleFileChange(event) {
    const fileInput = event.target;
    // const imageElement = document.getElementById(`image-${index}`);

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        // imageElement.src = e.target.result;
      };

      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  function handleImageRemoval(e) {
    if (e.target === e.currentTarget) {
      // Click occurred on the cell (drag-overlay)
      // Check if the click also occurred on the img
      const img = imageRef.current;
      if (img) {
        const imgBounds = img.getBoundingClientRect();
        const clickX = e.clientX;
        const clickY = e.clientY;

        // Check if the click is inside the bounds of the img
        if (
          clickX >= imgBounds.left &&
          clickX <= imgBounds.right &&
          clickY >= imgBounds.top &&
          clickY <= imgBounds.bottom
        ) {
          // Click occurred on the img, do nothing
          return;
        }
      }

      setImage();
    }
  }

  // return <img src={imageUrl} />;

  return (
    <Fragment>
      <div style={{ display: `${image ? "inherit" : "none"}`, width: "100%" }}>
        <img
          ref={imageRef}
          // id={`image-${index}`}
          src={image}
          alt="Image 1"
          onWheel={handleWheel}
          draggable="false"
        />{" "}
        <div
          className="drag-overlay"
          ref={overlay}
          onClick={handleImageRemoval}
        ></div>
      </div>

      {image ? null : (
        <Fragment>
          <div class="box-overlay"></div>
          <input
            type="file"
            accept="image/*"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            // id={`file-input-${index}`} // Add an ID to associate with the button
            onChange={(e) => handleFileChange(e)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default App;
